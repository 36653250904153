"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { useUser } from "@auth0/nextjs-auth0/client"
import { loadStripe } from "@stripe/stripe-js"
import { format } from "date-fns"
import {
  CheckCircle2,
  Info,
  MonitorIcon,
  SmartphoneIcon,
  X,
} from "lucide-react"

import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card"
import { Dialog, DialogContent } from "@/components/ui/dialog"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { ProductData } from "@/app/types/product"

import { useConfig } from "./config-component"
import { ActivePlanContent } from "./supervvisor-product-card/active-plan-content"

const imageCache: { [key: string]: string } = {}

interface MarketingFeature {
  name: string
}

interface ProductCardProps {
  product: ProductData
  productUrl: string
  isCancelled: boolean
  isPaymentPending: boolean
  selectedPriceId: string
  subscriptionDetails: any
}

export default function ProductCard({
  product,
  productUrl,
  isCancelled,
  isPaymentPending,
  selectedPriceId,
  subscriptionDetails,
}: ProductCardProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } = useConfig()
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [productDetailUrl, setProductDetailUrl] = useState<string | null>(null)
  const { user, isLoading: isUserLoading } = useUser()
  const router = useRouter()
  const [openTooltipIndex, setOpenTooltipIndex] = useState<number | null>(null)
  const [openDialog, setOpenDialog] = useState<number | null>(null)
  const [isActivePlanDialogOpen, setIsActivePlanDialogOpen] = useState(false)

  useEffect(() => {
    const imageUrl = product?.images?.[0]
    const productDetailUrl = productUrl
    if (imageUrl) {
      if (imageCache[imageUrl]) {
        setImageSrc(imageCache[imageUrl])
      } else {
        const img = new Image()
        img.src = imageUrl
        img.onload = () => {
          imageCache[imageUrl] = imageUrl
          setImageSrc(imageUrl)
        }
      }
    }
    if (productDetailUrl) {
      setProductDetailUrl(productDetailUrl)
    } else {
      setProductDetailUrl(`/marketplace/${product.id}`)
    }
  }, [product.images, productUrl])

  const handleSubscribe = async () => {
    const checkoutInfo = {
      priceId: product.prices[0].id,
      productId: String(product.id),
      timestamp: new Date().getTime(),
      returnTo: encodeURIComponent(window.location.pathname),
      setupPriceId: product.product_metadata?.setup_price_id || "",
    }
    if (!user && !isUserLoading) {
      router.push(
        `/products/${product.id}?tab=pricing&returnUrl=${encodeURIComponent(window.location.pathname)}`
      )
      return
    } else {
      router.push(
        `/marketplace/${product.id}?tab=pricing&returnUrl=${encodeURIComponent(window.location.pathname)}`
      )
      return
    }
    // User is logged in, proceed with checkout
    await processCheckout(checkoutInfo)
  }
  const processCheckout = async (checkoutInfo: {
    priceId: string
    productId: string
    setupPriceId?: string
  }) => {
    setIsLoading(true)
    try {
      const response = await fetch("/api/stripe/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId: checkoutInfo.priceId,
          productId: checkoutInfo.productId,
          setupPriceId: checkoutInfo.setupPriceId,
        }),
      })

      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const { sessionId } = await response.json()

      // Clear stored checkout info
      localStorage.removeItem("pendingCheckout")

      const stripe = await loadStripe(NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!)
      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId })
        if (error) {
          console.error("Stripe Checkout Error:", error)
        }
      } else {
        console.error("Failed to load Stripe")
      }
    } catch (error) {
      console.error("Error:", error)
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false)
    }
  }
  // Check for pending checkout on component mount
  useEffect(() => {
    // Only check if we haven't checked before and user is loaded
    if (user && !isUserLoading) {
      const pendingCheckout = localStorage.getItem("pendingCheckout")
      if (pendingCheckout) {
        try {
          const checkoutInfo = JSON.parse(pendingCheckout)
          const timestamp = checkoutInfo.timestamp
          const now = new Date().getTime()

          // Only process if the checkout is less than 1 hour old
          if (now - timestamp < 3600000) {
            processCheckout(checkoutInfo)
          } else {
            // Clear expired checkout info
            localStorage.removeItem("pendingCheckout")
          }
        } catch (error) {
          console.error("Error processing pending checkout:", error)
          localStorage.removeItem("pendingCheckout")
        }
      }
    }
  }, [user, isUserLoading])
  return (
    <Card className="relative flex h-full flex-col gap-[30px] overflow-hidden rounded-xl border border-secondary-300 bg-card p-7 hover:border hover:border-secondary hover:shadow-[0px_0px_20px_0px_rgba(0,0,0,0.10)]">
      {selectedPriceId && (
        <>
          {/* Desktop Tooltip */}
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="absolute right-2 top-2">
                <Badge
                  variant="default"
                  className={`rounded-full ${
                    subscriptionDetails?.subscription_status === "past_due"
                      ? "border-red-50 bg-primary-100 text-primary-500 hover:bg-primary-200"
                      : "bg-green-600 text-white hover:bg-green-500"
                  } `}
                >
                  {subscriptionDetails?.subscription_status === "past_due"
                    ? "Past Due"
                    : "Active Plan"}
                </Badge>
              </TooltipTrigger>
              <TooltipContent
                className="max-w-[300px] rounded-lg border bg-secondary p-4 text-white"
                side="bottom"
                align="end"
                sideOffset={5}
              >
                <ActivePlanContent
                  product={product}
                  subscriptionDetails={
                    subscriptionDetails && {
                      ...subscriptionDetails,
                      stripe_price_id: selectedPriceId,
                      subscription_renew_date:
                        subscriptionDetails.subscription_renew_date ||
                        new Date(),
                    }
                  }
                />
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          {/* Mobile Dialog */}
          <div className="absolute right-2 top-2 md:hidden">
            <Badge
              variant="default"
              className={`rounded-full ${
                subscriptionDetails?.subscription_status === "past_due"
                  ? "border-red-50 bg-primary-100 text-primary-500 hover:bg-primary-200"
                  : "bg-green-600 text-white hover:bg-green-500"
              } `}
              onClick={() => setIsActivePlanDialogOpen(true)}
            >
              {subscriptionDetails?.subscription_status === "past_due"
                ? "Past Due"
                : "Active Plan"}
            </Badge>
            <Dialog
              open={isActivePlanDialogOpen}
              onOpenChange={setIsActivePlanDialogOpen}
            >
              <DialogContent className="rounded-lg border bg-secondary p-4 text-white sm:max-w-[425px]">
                <ActivePlanContent
                  product={product}
                  subscriptionDetails={
                    subscriptionDetails && {
                      ...subscriptionDetails,
                      stripe_price_id: selectedPriceId,
                      subscription_renew_date:
                        subscriptionDetails.subscription_renew_date ||
                        new Date(),
                    }
                  }
                />
              </DialogContent>
            </Dialog>
          </div>
        </>
      )}
      <CardHeader className="items-start p-0">
        {imageSrc ? (
          /* eslint-disable-next-line @next/next/no-img-element */
          <img
            src={imageSrc}
            alt={product.name}
            className="max-h-[40px] xl:max-h-[50px]"
          />
        ) : (
          <div className="hidden min-h-[40px] w-full xl:min-h-[50px]" />
        )}
      </CardHeader>
      <CardContent className="flex flex-grow flex-col gap-[20px]">
        <h2 className="text-xl">{product.name}</h2>
        <p className="text-base leading-7">{product.description}</p>
        <ul className="space-y-4">
          {product.capabilities?.slice(0, 5).map((capability, index) => (
            <li key={index} className="flex items-start">
              <CheckCircle2 className="mr-2 mt-1 h-4 w-4 flex-shrink-0" />
              <span className="text-base">{capability.keyword}</span>
              {capability.description !== "" && (
                <>
                  {/* Desktop Tooltip */}
                  <div className="-mb-1 hidden md:block">
                    <TooltipProvider>
                      <Tooltip open={openTooltipIndex === index}>
                        <TooltipTrigger
                          onMouseEnter={() => setOpenTooltipIndex(index)}
                          onMouseLeave={() => setOpenTooltipIndex(null)}
                        >
                          <Info className="ml-2 mt-1 h-4 w-4 flex-shrink-0 text-secondary-600 hover:text-secondary-700" />
                        </TooltipTrigger>
                        <TooltipContent className="rounded-lg border bg-secondary p-4 text-white">
                          <h3 className="mb-2 text-lg text-white">
                            {capability.keyword}
                          </h3>
                          <p className="max-w-xs text-sm text-white">
                            {capability.description}
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>

                  {/* Mobile Dialog */}
                  <div className="-mb-1 md:hidden">
                    <Button
                      variant="ghost"
                      size="icon"
                      className="ml-2 h-4 w-4 border-0 p-0 hover:bg-transparent"
                      onClick={() => setOpenDialog(index)}
                    >
                      <Info className="h-4 w-4 flex-shrink-0 text-[#858585] hover:text-[#333333]" />
                    </Button>
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
        {/* <div className="flex items-center space-x-2">
          <span className="text-sm">Agents Available:</span>
          <span className="text-sm bg-gray-100 px-2 py-1 rounded">
            {product.metadata.agents_available || "N/A"}
          </span>
          <span className="text-sm text-gray-500">Sites: {product.metadata.sites || "N/A"}</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm">Current Agent Actions:</span>
          <span className="text-sm bg-gray-100 px-2 py-1 rounded">
            {product.metadata.current_agent_actions || "N/A"}
          </span>
        </div> */}
      </CardContent>
      <CardFooter className="flex items-center gap-[20px]">
        {!isPaymentPending && (
          <Button
            onClick={handleSubscribe}
            disabled={isLoading || isUserLoading}
            variant="subscribebtn"
            className="h-10"
          >
            {isLoading
              ? "Processing..."
              : subscriptionDetails
                ? isCancelled
                  ? "Re-Subscribe"
                  : "Upgrade"
                : "Subscribe"}
          </Button>
        )}
        <Link
          href={`${productDetailUrl}?returnUrl=${encodeURIComponent(window.location.pathname)}`}
        >
          <Button variant="primaryoutline" className="h-10">
            Details
          </Button>
        </Link>
        <div className="hidden space-x-2">
          <MonitorIcon className="text-gray-400" />
          <SmartphoneIcon className="text-gray-400" />
        </div>
      </CardFooter>
      <Dialog
        open={openDialog !== null}
        onOpenChange={() => setOpenDialog(null)}
      >
        <DialogContent className="rounded-lg border bg-secondary p-4 text-white sm:max-w-[425px]">
          {/* <div className="absolute right-4 top-2">
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 rounded-md border-0 p-0 hover:bg-transparent"
              onClick={() => setOpenDialog(null)}
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </div> */}
          <h3 className="font-medium leading-7 text-white">
            {openDialog !== null && product.capabilities?.[openDialog]?.keyword}
          </h3>
          <p className="text-sm font-normal leading-7">
            {openDialog !== null &&
              product.capabilities?.[openDialog]?.description}
          </p>
        </DialogContent>
      </Dialog>
    </Card>
  )
}
